import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Masonry from "react-masonry-css";

import PaginationLinks from "../common/PaginationLinks";
import NewsLink from "./NewsLink";

import * as classes from "./News.module.scss";

export default function News({data, pageContext: {humanPageNumber, previousPagePath, nextPagePath}}) {

    const edges = data["allNews"]["edges"];

    return (
        <section>
            <Helmet>
                <title>Новости{humanPageNumber > 1 ? `, страница ${humanPageNumber}` : ""}</title>
            </Helmet>
            <h1>Новости</h1>
            <Masonry
                className={classes.masonryGrid}
                columnClassName={classes.masonryGridColumn}
                breakpointCols={3}>
                {edges?.map(({node}) =>
                    <NewsLink
                        key={node.id}
                        className={classes.link}
                        {...node} />)}
            </Masonry>
            <PaginationLinks
                previousLinkText="Показать более свежие новости"
                nextLinkText="Показать более ранние новости"
                previousLinkTo={previousPagePath}
                nextLinkTo={nextPagePath} />
        </section>
    );

}

export const query = graphql`
    query($skip: Int, $limit: Int) {
        allNews(
            sort: {fields: [timestamp], order: DESC}
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    ...NewsLink
                }
            }
        }
    }
`;
